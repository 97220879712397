import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import "../assets/scss/main.scss"

import Header from "../components/header"
import Footer from "../components/footer"
import MetaPixel from "../utils/metaPixel"

const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => {
      return (
        <>
          <MetaPixel />
          <Header />
          <main>{children}</main>
          <Footer />

          <DummyForm />
        </>
      )
    }}
  />
)

function DummyForm() {
  return (
    <form
      name="register_header_v2"
      data-netlify="true"
      netlify-honeypot="bot-field"
      className="tw-hidden"
      hidden
    >
      <input type="hidden" name="subject" value="Registration of Interest from Found Huntingdale" />
      <input type="text" name="first_name" placeholder="First Name" required />
      <input type="text" name="last_name" placeholder="Last Name" required />
      <input type="email" name="email" placeholder="Contact Email" required />
      <input type="text" name="phone" placeholder="Phone" required />
      <select name="enquiry_type" required>
        <option value="">Enquiry</option>
        <option value="Sales Enquiry">
          Sales Enquiry
        </option>
        <option value="Leasing Enquiry">
          Leasing Enquiry
        </option>
      </select>
      <select name="how_you_hear" required>
        <option value="">How Did You Hear About Us</option>
        <option value="Realcommercial.com.au">Realcommercial.com.au</option>
        <option value="Commercialrealestate.com.au">
          Commercialrealestate.com.au
        </option>
        <option value="Site Signage">Site Signage</option>
        <option value="Ray White Email">Ray White Email</option>
        <option value="Hudson Bond Email">Hudson Bond Email</option>
        <option value='Crabtrees Email'>Crabtrees Email</option>
        <option value="Social Media">Social Media</option>
        <option value="Google">Google</option>
        <option value="Word of Mouth">Word of Mouth</option>
        <option value="Letterbox Flyer">Letterbox Flyer</option>
        <option value="Referral">Referral</option>
        <option value="Other">Other</option>
      </select>

      {/* <input
        placeholder="How did you hear about us?"
        name="how_you_hear_other"
        type="text"
      /> */}
    </form>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
